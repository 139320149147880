<template>
  <div id="cate">
    <div class="nav">
      <div @click="toSearch" class="nav-wrap">
        <!-- 搜索商品 -->
        <span class="iconfont icon_search"></span><span>{{language.l_5}}</span>
      </div>
    </div>
    <div class="main">
      <van-collapse v-model="activeName" accordion>
        <van-collapse-item v-for="(item, index) in dataInfo" :key="index" :title="item.name" :name="index + 1">
          <template #right-icon>
            <van-image width="100px" height="100px" :src="item.icon" />
          </template>
          <div class="cate_child">
            <van-cell v-for="item1 in item.children" :key="item1.id" @click="toSearchInfo(item1.id, item1.name)" :title="item1.name" is-link />
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>

<script>
import {getCate} from '@api'

export default {
  data() {
    return {
      dataInfo: [],
      language: this.$store.getters.language,
      activeName: '',
    }
  },
  created() {
    getCate().then(res => this.dataInfo = res.data);
  },
  methods: {
    toSearch() {  // 跳转搜索页
      this.$router.push({name: 'Search'})
    },
    toSearchInfo(cat_id, name) {  // 跳转搜索详情页
      this.$router.push({
        name: 'SearchInfo',
        query: {cat_id, name}
      })
    }
  }
}
</script>

<style lang="less" scoped>
#cate {
  .safe_padding_bottom(50px);
  .nav {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    font-size: 14px;
    background-color: #fff;
    padding: 10px 16px;
    box-shadow: 0 1px 10px #F8F8FF;
    z-index: 9;
    .nav-wrap {
      height: 34px;
      line-height: 34px;
      text-align: center;
      color:grey;
      background-color: #f9f9f9;
      border-radius: 15px;
      span {
        vertical-align: middle;
        &.icon_search {
          margin-right: 5px;
        }
      }
    }
  }
  .main {
    .van-collapse {
      /deep/ .van-collapse-item {
        margin-top: 6px;
        &:first-child {
          margin: 0;
        }
      }
      /deep/ .van-cell {
        padding: 0 10px;
      }
      /deep/ .van-collapse-item__title .van-cell__title {
        width: 255px;
        line-height: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      /deep/ .van-collapse-item__content {
        padding: 0;
      }
      .cate_child /deep/ .van-cell {
        padding: 10px 20px;
      }
    }
  }
}
</style>